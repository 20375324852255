<template>
  <el-dialog
    title="支付渠道配置"
    :visible="modalVisible"
    width="30%"
    left
    :show-close="false"
  >
    <el-form label-width="100px">
      <el-row :span="24">
        <el-col :span="24">
          <el-form-item label="支付渠道：">
            <el-input value="支付宝" disabled/>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="支付授权目录：">
            <el-input value="支付宝" disabled/>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="支付商户号：">
            <el-input value="支付宝" disabled/>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="支付秘钥：">
            <el-input value="支付宝" disabled/>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <span slot="footer" class="dialog-footer">
    <el-button @click="handleClose">取 消</el-button>
    <el-button type="primary" @click="handleOk">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
export default {
  name: "ConfigModal",
  props: {
    modalVisible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  methods:{
    handleClose(){
      this.$emit('cancel')
    },
    handleOk(){
      this.$emit('ok')
    }
  }
}
</script>

<style scoped>

</style>

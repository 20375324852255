<template>
  <div class="pay-config">
    <common-title title="支付渠道配置"> </common-title>
    <div class="pay-table-config">
      <vxe-grid border :data="tableData" :columns="tableColumn" size="small" v-bind="tableOptions">
        <template #operate="{ row }">
          <el-button type="text" @click="handleShowConfigModal">配置</el-button>
          <el-button type="text" @click="handlShowStatusModal(row.status)">
            {{ row.status === 0 ? '启用' : '停用' }}
          </el-button>
        </template>
        <template #pager>
          <i-pagination
            :page-size="iPagination.pageSize"
            :current-page="iPagination.currentPage"
            :page-sizes="[10, 20, 30, 40]"
            layout="prev, pager, next ,sizes"
            :total="400"
            @current-change="handlePageChange"
            @size-change="handleSizeChange"
          />
        </template>
      </vxe-grid>
    </div>
    <config-modal :modal-visible.sync="configVisible" @cancel="handleCancel" @ok="handleOk" />
    <el-dialog title="确认提示" :visible.sync="statusVisible" width="30%">
      <span v-show="status" class="tips"
        ><i class="iconfont icon-tyyiwen icon" style="color: red"></i
        >停用支付渠道后，用户进行支付时，不可选择本支付渠道进行支付</span
      >
      <span v-show="!status" class="tips"
        ><i class="iconfont icon-tyyiwen icon"></i
        >启用支付渠道后，用户进行支付时，可选择本支付渠道进行支付</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="statusVisible = false">取 消</el-button>
        <el-button type="primary" @click="statusVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ConfigModal from './components/ConfigModal.vue';
import IPagination from '@/components/i-pagination/index.vue';
import CommonTitle from '@/components/common-title/index.vue';

export default {
  name: 'PayConfig',
  components: {
    CommonTitle,
    ConfigModal,
    IPagination
  },
  data() {
    return {
      status: 0,
      configVisible: false,
      statusVisible: false,
      iPagination: {
        pageSize: 10,
        currentPage: 1
      },
      tableColumn: [
        { type: 'seq', title: '序号' },
        { field: 'id', title: '支付渠道ID' },
        { field: 'name', title: '支付渠道' },
        { field: 'status', title: '状态' },
        { title: '操作', slots: { default: 'operate' }, width: 150 }
      ],
      tableData: [
        {
          id: '1skjdakl',
          name: '支付宝',
          status: 0
        }
      ],
      tableOptions: {}
    };
  },
  methods: {
    handleClick() {
      window.history.pushState(null, null, '/index/product/list');
    },
    handlePageChange() {},
    handleSizeChange() {},
    handleShowConfigModal() {
      this.configVisible = true;
    },
    handleCancel() {
      this.configVisible = false;
    },
    handleOk() {
      this.configVisible = false;
    },
    handlShowStatusModal(status) {
      this.status = status;
      this.statusVisible = true;
    },
    changeConfig() {
      // 停用请求
    }
  }
};
</script>

<style lang="less" scoped>
@import './index';
</style>
